/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { uniqueId, kebabCase } from 'lodash'
import { Styled } from 'theme-ui'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, CallToAction } from '../../components/elements'

const FacilitiesSystems = ({ data }) => {

    const page = data.prismic.facilitiesSystems

    return (
        <Layout>
            <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
            <PubHero title={RichText.asText(page.title)} />
            <Section>
                <Container>
                    <Flex flexWrap="wrap" justifyContent="center">
                        <Box width={['full', 4 / 5, 3 / 4]} sx={{
                            backgroundColor: 'wrapperbox',
                            padding: [4, 5],
                        }}>
                            {RichText.render(page.content)}
                            <hr />

                            {page.body.map(({ primary, fields }) => (
                                <Flex flexWrap="wrap" key={`${uniqueId('facility_')}`} id={`${kebabCase(RichText.asText(primary.title1))}`} sx={{ textAlign: 'center' }} mt={5}>
                                    <Box width="full">{RichText.render(primary.title1)}</Box>
                                    {fields.map(({ description, number }) => (
                                        <Box key={`${uniqueId('stat_')}`} width={[1 / 2, 1 / 2, 1 / 3]}
                                            sx={{
                                                py: [2, 3, 4],
                                                borderBottom: ['1px solid'],
                                                padding: 3,
                                                borderColor: 'gray.2',
                                            }}>
                                            <Styled.h3 sx={{ fontSize: [5, 6], fontWeight: 'light', color: 'primary' }}>{RichText.asText(number)}</Styled.h3>
                                            {RichText.render(description)}
                                        </Box>
                                    ))}
                                </Flex>
                            ))}
                        </Box>
                    </Flex>
                </Container>
            </Section>

            <CallToAction />
        </Layout>
    )
}

export default FacilitiesSystems

export const query = graphql`
    query FacilitiesSystemsQuery {
        prismic {
            facilitiesSystems(lang: "en-us", uid: "facilities-systems") {
            title
            content
            meta_title
            meta_description
            _linkType
            body {
                ... on PRISMIC_FacilitiesSystemsBodyStats {
                type
                label
                fields {
                    description
                    number
                }
                primary {
                    title1
                }
            }
            }
            }
        }
    }
`